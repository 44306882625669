import Vue from 'vue'
import Router from 'vue-router'
import CheckoutComponent from './views/Checkout';
import NotFoundPlanOrFee from './views/NotFoundPlanOrFee';
import CurrencyNotAvailable from './views/CurrencyNotAvailable';
import UserBlockFraud from './views/UserBlockFraud';
import PlanOrFeeInactive from './views/PlanOrFeeInactive';
import Unavailable from './views/Unavailable';
import PaymentAdmin from './views/PaymentAdmin';
import Plans from './views/Plans';
import PlansLegacy from './views/PlansLegacy';

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        { path: '/payment/:slug', name: 'checkout', component: CheckoutComponent },
        { path: '/plan-or-fee-inactive/:slug', name: 'PlanOrFeeInactive', component: PlanOrFeeInactive},
        { path: '/unavailable', name: 'Unavailable', component: Unavailable, props: true},
        { path: '/not-found-plan-or-fee', name: 'NotFoundPlanOrFee', component: NotFoundPlanOrFee, props: true },
        { path: '/currency-not-available', name: 'CurrencyNotAvailable', component: CurrencyNotAvailable, props: true },
        { path: '/block-user', name: 'UserBlockFraud', component: UserBlockFraud },
        { path: '/pAdmn', name: 'PaymentAdmin', component: PaymentAdmin },
        { path: '/plans', name: 'Plans', component: Plans, alias: '/plans-trial' },
        { path: '/plans-legacy', name: 'Plans', component: PlansLegacy },
        { path: '*', name: 'NotFoundPlanOrFee', component: NotFoundPlanOrFee }
    ]
})
