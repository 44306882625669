<template>
    <div class="detailsPlanOrFee">
        <div class="detailsPlanOrFee_tool">
            <div class="detailsPlanOrFee_tool_header" :class="{detailsPlanOrFee_tool_headerAnnual: planCountryPrice.hasOwnProperty('annual') && showChangePeriod}">
                <div class="detailsPlanOrFee_tool_header_name">
                    <p>{{ translateWord('Carrinho') }}</p>
                </div>

                <div v-if="showChangePeriod" class="detailsPlanOrFee_tool_header_actions">
                    <span @click="changePeriodCharge('MONTHLY')"
                        class="detailsPlanOrFee_tool_header_actions_action"
                        :class="{active: recurrencePeriod == 'MONTHLY'}"
                    >{{ translateWord('Mensal') }}</span>

                    <span @click="changePeriodCharge('YEARLY')"
                        class="detailsPlanOrFee_tool_header_actions_action"
                        :class="{active: recurrencePeriod == 'YEARLY'}"
                    >{{ translateWord('Anual') }}</span>

                    <span v-if="planCountryPrice.hasOwnProperty('annual')" class="detailsPlanOrFee_tool_header_actions_saveMoneyAnnual">
                        {{ translateWord('Economize anualmente 10%') }}
                    </span>
                </div>
            </div>

            <div class="detailsPlanOrFee_tool_items">
                <div class="detailsPlanOrFee_tool_items_item">
                    <div class="detailsPlanOrFee_tool_items_item_product">
                        <div class="detailsPlanOrFee_tool_items_item_product_info">
                            <h1 class="detailsPlanOrFee_tool_items_item_product_info_title">
                                {{ product.title }}
                            </h1>
                        </div>
                    </div>

                    <div class="detailsPlanOrFee_tool_items_item_price">
                        <p v-if="product.isTrialDays" class="detailsPlanOrFee_tool_items_item_price_trialDays">
                            <span><del>{{ product.currencySymbol }}{{ product.isTheCurrency.eur ? productAmountPlusVatFee(false, true) : product.amount }}</del></span>
                        </p>

                        <p v-else-if="product.isBuyAnnual">
                            {{ product.currencySymbol }}{{ planCountryPrice.annual.monthAmount }}

                            <span v-if="isPlan" class="periodSymbol">/{{ translateWord('me') }}</span>
                        </p>

                        <p v-else>
                            {{ product.currencySymbol }}{{ hasBumpsellAndSelected ? product.amountFixed : product.amount }}

                            <span v-if="isPlan" class="periodSymbol">/{{ product.periodSymbol }}</span>
                        </p>
                    </div>
                </div>
            </div>

            <div
                v-if="!isPlan"
                class="detailsPlanOrFee_description" 
                v-html="product.description"></div>


            <div v-if="product.currency == 'USD'" class="detailsPlanOrFee_bumpsell">
                <Bumpsell/>
            </div>
            <div v-if="isPlan && !product.ignoreListTools" class="detailsPlanOrFee_tool_toolsList">
                <div class="detailsPlanOrFee_tool_toolsList_items">
                    <div v-if="product.resources.domains.qtd" class="detailsPlanOrFee_tool_toolsList_item">
                        <p class="detailsPlanOrFee_tool_toolsList_item_name">{{ translateWord('{0} Domínio [a]', [product.resources.domains.qtd || '0']) }}</p>
                        <p class="detailsPlanOrFee_tool_toolsList_item_desc">{{ translateWord('Incluído') }}</p>
                    </div>

                    <div v-if="product.resources.subdomains.qtd" class="detailsPlanOrFee_tool_toolsList_item">
                        <p class="detailsPlanOrFee_tool_toolsList_item_name">{{ translateWord('{0} Subdomínios', [product.resources.subdomains.qtd || '0']) }}</p>
                        <p class="detailsPlanOrFee_tool_toolsList_item_desc">{{ translateWord('Incluído') }}</p>
                    </div>

                    <!--<div class="detailsPlanOrFee_tool_toolsList_item">
                        <p class="detailsPlanOrFee_tool_toolsList_item_name">{{ translateWord('{0} Assinantes', [product.resources.leads.qtd]) }}</p>
                        <p class="detailsPlanOrFee_tool_toolsList_item_desc">{{ translateWord('Incluído') }}</p>
                    </div>-->

                    <div v-if="product.resources.storage.qtd && parseInt(product.resources.storage.qtd) != 0" class="detailsPlanOrFee_tool_toolsList_item">
                        <p class="detailsPlanOrFee_tool_toolsList_item_name">{{ translateWord('{0} GB Espaço em disco', [parseInt(product.resources.storage.qtd) || '0']) }}</p>
                        <p class="detailsPlanOrFee_tool_toolsList_item_desc">{{ translateWord('Incluído') }}</p>
                    </div>

                    <div class="detailsPlanOrFee_tool_toolsList_item" v-for="tool in product.tools.slice(0, moreToolsControl)" :key="tool">
                        <p class="detailsPlanOrFee_tool_toolsList_item_name">{{ tool }}</p>
                        <p class="detailsPlanOrFee_tool_toolsList_item_desc">{{ translateWord('Incluído') }}</p>
                    </div>

                    <div v-if="showListPlusTools" class="detailsPlanOrFee_tool_toolsList_item" v-for="tool in product.tools.slice(moreToolsControl)" :key="tool">
                        <p class="detailsPlanOrFee_tool_toolsList_item_name">{{ tool }}</p>
                        <p class="detailsPlanOrFee_tool_toolsList_item_desc">{{ translateWord('Incluído') }}</p>
                    </div>
                </div>

                <div v-if="product.tools.length > moreToolsControl" class="detailsPlanOrFee_tool_toolsList_actions">
                    <p @click="showListPlusTools = !showListPlusTools" class="detailsPlanOrFee_tool_toolsList_plusTool">
                        <span><i class="icon" :class="[{'icon-minus-circle': showListPlusTools, 'icon-plus-circle': !showListPlusTools}]"></i></span>
                        {{ (product.tools.length - moreToolsControl) }} {{ translateWord('Mais ferramentas adicionais') }}
                    </p>
                </div>

                <div v-if="showTotalValue && isPaymentCountryPrice" class="detailsPlanOrFee_tool_toolsList_total">
                    <p class="detailsPlanOrFee_tool_toolsList_total_name">{{ translateWord('Valores totais') }}</p>
                    <p class="detailsPlanOrFee_tool_toolsList_total_desc">
                        <del>{{ product.currencySymbol }}{{ !product.isBuyAnnual ? planCountryPrice.fakeAmount : planCountryPrice.annual.amountRef }}/{{ product.periodSymbol }}</del>
                    </p>
                </div>
            </div>

            <!-- Buy Storage Details -->
            <div v-if="product.storage_kbs" class="detailsPlanOrFee_tool_toolsList">
                <div class="detailsPlanOrFee_tool_toolsList_items">
                    <div class="detailsPlanOrFee_tool_toolsList_item">
                        <p class="detailsPlanOrFee_tool_toolsList_item_name">{{ translateWord('{0} GB Espaço em disco', [this.convertGb(product.storage_kbs)]) }}</p>
                        <p class="detailsPlanOrFee_tool_toolsList_item_desc">{{ translateWord('Incluído') }}</p>
                    </div>
                </div>
            </div>

            <!-- Buy Domanin Details -->
            <div v-if="isPaymentDomainRegistry" class="detailsPlanOrFee_tool_toolsList">
                <div class="detailsPlanOrFee_tool_toolsList_items">
                    <div class="detailsPlanOrFee_tool_toolsList_item">
                        <p class="detailsPlanOrFee_tool_toolsList_item_name">{{ translateWord('Domínio K1') }}</p>
                        <p class="detailsPlanOrFee_tool_toolsList_item_desc">{{ product.domain }}</p>
                    </div>

                    <div v-if="product.domainProtection" class="detailsPlanOrFee_tool_toolsList_item">
                        <p class="detailsPlanOrFee_tool_toolsList_item_name">{{ translateWord('Proteção de Privacidade') }}</p>
                        <p class="detailsPlanOrFee_tool_toolsList_item_desc">{{ translateWord('Incluído') }}</p>
                    </div>

                    <div class="detailsPlanOrFee_tool_toolsList_item">
                        <p class="detailsPlanOrFee_tool_toolsList_item_name">{{ translateWord('Validade') }}</p>
                        <p class="detailsPlanOrFee_tool_toolsList_item_desc">{{ translateWord('{0} Ano', [product.domainTime]) }}</p>
                    </div>
                </div>
            </div>

            <!--<div v-if="isBuyTool" class="detailsPlanOrFee_tool_toolsAddList">
                <div class="detailsPlanOrFee_tool_items_item" v-for="(tool, index) in toolsAddCart" :key="index">
                    <icon @click.native="removeTool(tool)" class="detailsPlanOrFee_tool_items_item_delete" name="delete" />

                    <div class="detailsPlanOrFee_tool_items_item_product">
                        <div class="detailsPlanOrFee_tool_items_item_product_image">
                            <img :src="require('../assets/no-image.png')" alt="Sem Imagem" title="Sem Imagem">
                        </div>

                        <div class="detailsPlanOrFee_tool_items_item_product_info">
                            <h1 class="detailsPlanOrFee_tool_items_item_product_info_title">
                                {{ tool.name }}
                            </h1>
                            <p class="detailsPlanOrFee_tool_items_item_product_info_desc">
                                {{ tool.description }}
                            </p>
                        </div>
                    </div>

                    <div class="detailsPlanOrFee_tool_items_item_price">
                        <p>{{ product.currencySymbol }}{{ tool.amount.toFixed(2) }}</p>
                    </div>
                </div>
            </div>-->
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import Helper from '../../assets/js/helper';
import Bumpsell from '../Bumpsell/Bumpsell.vue';

export default {
    name: "DetailsPlanOrFee",
    props: {
        showTotalValue: {
            type: Boolean,
            default: true
        },
        showChangePeriod: {
            type: Boolean,
            default: true
        }
    },
    data(){
        return{
            showListPlusTools: false,
            moreToolsControl: 2,
            recurrencePeriod: 'MONTHLY', //MONTH - YEAR
        }
    },
    created(){
        if(this.$route.query['period'] != undefined && this.$route.query['period'] == 'y') this.recurrencePeriod = 'YEARLY';

        if(!this.product.resources.domains.qtd) this.moreToolsControl++;
        if(!this.product.resources.subdomains.qtd) this.moreToolsControl++;
        if(!this.product.resources.storage.qtd || parseInt(this.product.resources.storage.qtd) == 0) this.moreToolsControl++;
    },
    computed: {
        ...mapState([
            'isBuyTool',
            'planCountryPrice',
            'isPaymentCountryPrice',
            'isPaymentDomainRegistry'
        ]),
        ...mapGetters([
            'hasCouponAndIsValid',
            'product',
            'translateWord',
            'isPlan',
            'hasBumpsellAndSelected'
        ])
    },
    methods: {
        ...mapMutations([
            'changePeriodRecurring',
            'clearCoupon'
        ]),
        changePeriodCharge(period){
            this.clearCoupon();
            
            this.changePeriodRecurring(period == 'MONTHLY' ? 'MONTHLY' : 'YEARLY');

            this.recurrencePeriod = period;
        },
        convertGb(storageGb){
            return Helper.convertGb(storageGb);
        }
    },
    components: {
        Bumpsell
    }
}
</script>

<style lang="scss">
    @import "../../scss/mixins";

    .detailsPlanOrFee_tool{
        @extend %cart;
    }

    .detailsPlanOrFee_tool_header,
    .detailsPlanOrFee_addTool_header{
        @extend %cartHeader;
    }
    .detailsPlanOrFee_tool_header_name,
    .detailsPlanOrFee_addTool_header_name{
        @extend %cartHeaderName;
    }
    .detailsPlanOrFee_tool_header_actions{
        position: relative;
        display: flex;
        z-index: 10;
        background: var(--stepLeftColor);
        border: 2px solid #D9D9D9;
        border-radius: 100px;
        font-size: 0.7em;
        padding: 5px;
        font-weight: 500;
    }
    .detailsPlanOrFee_tool_header_actions_action{
        margin-right: -1px;
        padding: 4px 8px;
        border-radius: 100px;
        cursor: pointer;

        &.active{
            background: var(--green);
            color: var(--white);
        }
        &:last-child{
            margin-right: 0px;
        }
    }
    .detailsPlanOrFee_tool_headerAnnual{
        &:before{
            left: 0px;
            width: 60%;
        }

        & .detailsPlanOrFee_tool_header_actions{
            margin-right: 65px;
        }
    }
    .detailsPlanOrFee_tool_header_actions_saveMoneyAnnual{
        position: absolute;
        top: 50%;
        left: 100%;
        width: 100%;
        transform: translateY(-50%);
        margin-left: 10px;
        color: var(--blue)
    }

    .detailsPlanOrFee_tool_items{
        display: flex;
        flex-direction: column;
        margin-bottom: -21px;
    }
    .detailsPlanOrFee_tool_items_item{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 25px 0px;
        position: relative;
    }
    .detailsPlanOrFee_tool_items_item_delete{
        position: absolute;
        top: 0px;
        right: 10px;
        font-size: 1em;
        color: #909090;
        cursor: pointer;
    }
    .detailsPlanOrFee_tool_items_item_product{
        display: flex;
        align-items: center;
    }
    .detailsPlanOrFee_tool_items_item_product_info_title{
        color: var(--blue);
        font-weight: 600;
        font-size: 1.4em;
    }
    .detailsPlanOrFee_tool_items_item_product_info_desc{
        font-size: 0.8em;
        font-weight: 500;
    }

    .detailsPlanOrFee_tool_items_item_price{
        align-self: flex-end;
        text-align: right;
        color: #505050;

        & p {
            font-weight: 600;
            font-size: 1.5em;
            margin-right: 10px;
        }
    }

    .detailsPlanOrFee_tool_features{
        display: flex;
        flex-wrap: wrap;
    }
    .detailsPlanOrFee_tool_features_item{
        width: 50%;
        margin-bottom: 10px;

        & img {
            width: 10px;
        }
    }

    .detailsPlanOrFee_tool_toolsList_plusTool{
        cursor: pointer;
        border-radius: 5px;
    }
    .detailsPlanOrFee_tool_toolsList_items{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .detailsPlanOrFee_tool_toolsList_item{
        display: flex;
        justify-content: space-between;
        padding: 2px 0px;
        font-size: 0.85em;
    }
    .detailsPlanOrFee_tool_toolsList_actions{
        display: flex;
        font-size: 0.85em;
    }
    .detailsPlanOrFee_tool_toolsList_total{
        display: flex;
        justify-content: space-between;
        padding-top: 15px;
        font-size: 0.85em;
        margin-top: 15px;
    }

    .detailsPlanOrFee_tool_items_item_price_trialDays{
        font-size: 1.4em;
        color: var(--gray-800);
    }

    .detailsPlanOrFee_tool_toolsAddList{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .detailsPlanOrFee_tool_toolsAddList_item_actions{
        font-size: 0.55em;

        & .icon{
            font-size: 1em;
            vertical-align: middle;
        }
    }
    .detailsPlanOrFee_tool_toolsAddList_item_actions_action{
        padding: 3px 6px;
        background: var(--gray-100);
        color: var(--white);
        border-radius: 10px;
        cursor: pointer;

        &.add:hover{
            background: var(--blue);
        }

        &.delete:hover{
            background: var(--red);
        }
    }

    .detailsPlanOrFee_description{
        font-size: 0.8em;
    }
</style>