<template>
    <div class="plans" v-if="loadTranslation">

        <!--<Banner :banners="listBanners" class="container plans_banner" />-->

        <div class="container plans_login">
            <div class="content">
                <div class="plans_login_item plans_login_socialMedia">
                    <a :href="links.facebook" class="icon icon-facebook"></a>
                    <a :href="links.instagram" class="icon icon-instagram"></a>
                </div>

                <a :href="`${links.getSupport}${allParams()}`" class="plans_login_item plans_login_support">{{ translateWord('Suporte') }}</a>

                <a :href="`${links.login}${allParams()}`" class="plans_login_item plans_login_login">{{ translateWord('Login') }} <i class="icon icon-arrow-up-right"></i></a>
            </div>
        </div>

        <div class="container plans_menu">
            <div class="content">
                <a :href="`${links.home}${allParams()}`" class="plans_menu_logo">
                    <img :src="require('../assets/builderall-marketing-suite.svg')" alt="Builderall" title="Builderall">
                </a>

                <div class="plans_menu_menuList">
                    <a class="plans_menu_menuList_link" :href="`${links.home}${allParams()}`">{{ translateWord('Home') }}</a>
                    
                    <div class="plans_menu_menuList_link" @click="subenuActive('tools')"> <!-- @mouseenter="subenuActive('tools')" @mouseleave="hiddenSubmenu" -->
                        {{ translateWord('Nossas Ferramentas') }} <i class="arrow down"></i>

                        <div class="plans_menu_menuList_subListBigMenu" v-show="isTools">
                            <div class="plans_menu_menuList_subListBigMenu_menu plans_menu_menuList_subListBigMenu_menuFreeTool">
                                <p class="plans_menu_menuList_subListBigMenu_menu_header">
                                    {{ translateWord('Nossas Ferramentas Gratuitas') }}
                                </p>

                                <a class="plans_menu_menuList_subListBigMenu_menu_link plans_menu_menuList_subListBigMenu_menu_linkSupercharge" :href="`${links.bigmenu.ourTools.superchargeBundle}${allParams()}`">
                                    <img :src="require('../assets/imagem/supercharge-black.png')" alt="Supercharge" title="Supercharge">
                                    <span>Bundle</span>
                                </a>

                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.ourTools.superchargeBundle}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Social Proof') }}</p>
                                    <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Evidencie seus clientes e turbine suas conversões!') }}</span>
                                </a>

                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.ourTools.superchargeBundle}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Notificações de Navegador') }}</p>
                                    <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Mantenha seu publico conectado') }}</span>
                                </a>

                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.ourTools.superchargeBundle}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Live Chat') }}</p>
                                    <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Converse em tempo real com os visitantes do site. Ofereça suporte ao vivo.') }}</span>
                                </a>

                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.ourTools.superchargeBundle}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Exit Pop Up') }}</p>
                                    <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Ofereça conteúdos e ofertas exclusivas para manter o interesse dos usuários.') }}</span>
                                </a>

                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.ourTools.superchargeBundle}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Meta Autoresponder') }}</p>
                                    <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Responda automaticamente aos comentários na Meta, aumente o engajamento e converta mais.') }}</span>
                                </a>
                            </div>

                            <div class="plans_menu_menuList_subListBigMenu_menu plans_menu_menuList_subListBigMenu_menuBuild">
                                <p class="plans_menu_menuList_subListBigMenu_menu_header">
                                    {{ translateWord('Criação e Suporte') }}
                                </p>

                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.ourTools.websites}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Websites') }}</p>
                                    <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Poderoso editor de arrastar e soltar - não precisa de códigos.') }}</span>
                                </a>

                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.ourTools.funnels}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Funis') }}</p>
                                    <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Converta visitantes em clientes com o Construtor de Funis.') }}</span>
                                </a>

                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.ourTools.courses}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Cursos') }}</p>
                                    <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Crie e venda seu curso online com o eLearning.') }}</span>
                                </a>

                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.ourTools.webinars}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Webnarios') }}</p>
                                    <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Hospde e gerencie seus webinários para promover seus serviços.') }}</span>
                                </a>

                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.ourTools.ecommerce}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Lojas Virtuais') }}</p>
                                    <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Venda seus produtos e cresça seu negócio.') }}</span>
                                </a>
                            </div>

                            <div class="plans_menu_menuList_subListBigMenu_menu plans_menu_menuList_subListBigMenu_menuEngage">
                                <p class="plans_menu_menuList_subListBigMenu_menu_header">
                                    {{ translateWord('Envolva & Automatize') }}
                                </p>

                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.ourTools.chatbots}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Website Charbot') }}</p>
                                    <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Adicione Automações e Chats em tempo real em seus websites.') }}</span>
                                </a>

                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.ourTools.mailingboss}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Email Marketing') }}</p>
                                    <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Envie e-mails, crie automações e desenvolva workflows com o Mailing Boss.') }}</span>
                                </a>

                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.ourTools.messagerIgChatbots}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Messenger/IG ChatBots') }}</p>
                                    <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Automatize seus chats na plataforma Meta.') }}</span>
                                </a>

                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.ourTools.booking}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Reservas & Agendamentos') }}</p>
                                    <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Reserve chamadas, agende reuniões e compartilhe a sua agenda.') }}</span>
                                </a>

                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.ourTools.ourTools}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Todas as ferramentas') }}</p>
                                </a>
                            </div>
                        </div>
                    </div>

                    <a class="plans_menu_menuList_link" @click="subenuActive('solutions')"> <!-- @mouseenter="subenuActive('solutions')" @mouseleave="hiddenSubmenu" -->
                        {{ translateWord('Soluções') }} <i class="arrow down"></i>

                        <div class="plans_menu_menuList_subListBigMenu" v-show="isSolution">
                            <div class="plans_menu_menuList_subListBigMenu_menu plans_menu_menuList_subListBigMenuSolutions_menuLeft">
                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.solutions.healthWellness}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Saúde & Bem-estar') }}</p>
                                    <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Elevando o nível da sua marca de saúde e bem-estar.') }}</span>
                                </a>
                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.solutions.agencies}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Agências') }}</p>
                                    <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Coloque a máquina para trabalhar e realize seus grandes sonhos!') }}</span>
                                </a>
                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.solutions.realEstate}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Imobiliária') }}</p>
                                    <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Abrindo portas para o seu sucesso.') }}</span>
                                </a>
                            </div>

                            <div class="plans_menu_menuList_subListBigMenu_menu plans_menu_menuList_subListBigMenuSolutions_menuCenter">
                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.solutions.startup}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Startups') }}</p>
                                    <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Da ideação à escala. Nós ajudamos você.') }}</span>
                                </a>
                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.solutions.freelancer}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Freelancers') }}</p>
                                    <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Construído para empreendedores individuais e iniciantes!') }}</span>
                                </a>
                                <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.solutions.courseCreator}${allParams()}`">
                                    <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Criadores de Cursos') }}</p>
                                    <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Mostre ao mundo o que você tem!') }}</span>
                                </a>
                            </div>

                            <div class="plans_menu_menuList_subListBigMenu_menu plans_menu_menuList_subListBigMenuSolutions_menuRight">
                                <div>
                                    <p class="plans_menu_menuList_subListBigMenuSolutions_menuRight_header">
                                        {{ translateWord('Aprenda, ganhe e contrate') }}
                                    </p>
                                </div>

                                <div>
                                    <div class="plans_menu_menuList_subListBigMenu_menu">
                                        <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.solutions.builderallLearn}${allParams()}`">
                                            <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Builderall Learn') }}</p>
                                            <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Obtenha todas as habilidades necessárias para prosperar no marketing digital.') }}</span>
                                        </a>

                                        <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.solutions.builderallCertified}${allParams()}`">
                                            <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Contrate um parceiro Certificado Builderall') }}</p>
                                            <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Parceiro certificado. Precisa de ajuda para criar seu projeto? Busque parceiros aqui.') }}</span>
                                        </a>
                                    </div>

                                    <div class="plans_menu_menuList_subListBigMenu_menu">
                                        <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.solutions.affiliate}${allParams()}`">
                                            <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Se torne um Afiliado') }}</p>
                                            <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Tenha ganhos recorrentes com a Builderall.') }}</span>
                                        </a>

                                        <a class="plans_menu_menuList_subListBigMenu_menu_link" :href="`${links.bigmenu.solutions.integration}${allParams()}`">
                                            <p class="plans_menu_menuList_subListBigMenu_menu_link_name">{{ translateWord('Integrações') }}</p>
                                            <span class="plans_menu_menuList_subListBigMenu_menu_link_description">{{ translateWord('Integre perfeitamente suas ferramentas favoritas com o Builderall.') }}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a class="plans_menu_menuList_link active" :href="`${allParams()}`">{{ translateWord('Planos') }}</a>
                </div>

                <div id="plansBa"></div>

                <div class="plans_menu_actions">
                    <a href="" class="btn2 btn_blue">{{ translateWord('Comece Agora') }}</a>
                    <span @click="viewMenuResponsive = !viewMenuResponsive" class="menuResponsive icon icon-align-justify"></span>
                </div>
            </div>

            <div v-if="viewMenuResponsive" class="plans_menu_menuListResponsive">
                <a class="plans_menu_menuList_link" :href="`${links.home}${allParams()}`">{{ translateWord('Home') }}</a>
                
                <a class="plans_menu_menuList_link" @click="subenuActive('solutions')">
                    {{ translateWord('Soluções') }}

                    <div class="plans_menu_menuList_subList" v-show="isSolution">
                        <a class="plans_menu_menuList_link" :href="`${links.solutions.healthWellness}${allParams()}`">{{ translateWord('Saúde e bem estar') }}</a>
                        <a class="plans_menu_menuList_link" :href="`${links.solutions.realEstate}${allParams()}`">{{ translateWord('Imobiliária') }}</a>
                        <a class="plans_menu_menuList_link" :href="`${links.solutions.freelancers}${allParams()}`">{{ translateWord('Freelancers') }}</a>
                        <a class="plans_menu_menuList_link" :href="`${links.solutions.agencies}${allParams()}`">{{ translateWord('Agências') }}</a>
                        <a class="plans_menu_menuList_link" :href="`${links.solutions.startups}${allParams()}`">{{ translateWord('Startups') }}</a>
                        <a class="plans_menu_menuList_link" :href="`${links.solutions.courseCreator}${allParams()}`">{{ translateWord('Criador do curso') }}</a>
                    </div>
                </a>

                <a class="plans_menu_menuList_link" @click="subenuActive('tools')">
                    {{ translateWord('Nossas Ferramentas') }}

                    <div class="plans_menu_menuList_subList" v-show="isTools">
                        <a class="plans_menu_menuList_link" :href="`${links.tools.website}${allParams()}`">{{ translateWord('Construtor de sites') }}</a>
                        <a class="plans_menu_menuList_link" :href="`${links.tools.emailMarketing}${allParams()}`">{{ translateWord('Email Marketing') }}</a>
                        <a class="plans_menu_menuList_link" :href="`${links.tools.courseCreator}${allParams()}`">{{ translateWord('Criador do curso') }}</a>
                        <a class="plans_menu_menuList_link" :href="`${links.tools.chatbots}${allParams()}`">{{ translateWord('Chatbots') }}</a>
                        <a class="plans_menu_menuList_link" :href="`${links.tools.funnelBuilder}${allParams()}`">{{ translateWord('Construtor de funil') }}</a>
                        <a class="plans_menu_menuList_link" :href="`${links.tools.allTools}${allParams()}`">{{ translateWord('Todas as ferramentas') }}</a>
                    </div>
                </a>
                <a class="plans_menu_menuList_link active" :href="`${allParams()}`">{{ translateWord('Planos') }}</a>
            </div>
        </div>

        <div class="container containerAdapter plans_planType">
            <div class="content">
                <div class="plans_planType_content">
                    <h1 class="plans_planType_content_textTitle">{{ translateWord('Construa websites. Envie Emails.') }}</h1>
                    <h1 class="plans_planType_content_textTitle">{{ translateWord('Cresça seu negócio. Tudo em um lugar.') }}</h1>

                    <div class="plans_planType_content_text_action">
                        <div v-if="planTypeView == 'normal'">
                            <p v-html="translateWord('Usuário do WordPress? Hospede seu website e tenha todos os benefícios da nossa plataforma tudo-em-um.')"></p> 
                            <span @click="changePlanType('wp')">{{ translateWord('Clique aqui') }}</span>
                        </div>

                        <div v-if="planTypeView == 'wp'">
                            <p v-html="translateWord('Não é usuário do WordPress? Veja todos os nossos planos de Construtor de website + marketing tudo-em-um.')"> </p>
                            <span @click="changePlanType('normal')">{{ translateWord('Clique aqui') }}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="plans_planType_left">
                    <h1 class="plans_planType_left_title">{{ translateWord('Tudo que você precisa em um pagamento mensal') }}<span>.</span></h1>
                </div>

                <div class="plans_planType_right">
                    <div class="plans_planType_right_content">
                        <p v-html="translateWord('Escolha seu <b>plano mensal</b>, acesse seu escritório e <b>comece a construir seu negócio.</b>')" class="plans_planType_right_desc"></p>

                        <div v-if="!isUrlPlansTrial" class="plans_planType_right_actions">
                            <p @click="changePlanType('normal')" class="plans_planType_right_actions_action" :class="[planTypeView == 'normal' ? 'btn2 btn_blue' : 'btn3']">{{ translateWord('Planos Builderall') }}</p>
                            <p @click="changePlanType('wp')" class="plans_planType_right_actions_action" :class="[planTypeView == 'wp' ? 'btn2 btn_blue' : 'btn3']">{{ translateWord('Planos WordPress') }}</p>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="container containerAdapter plans_info">
            <div class="content">
                <div class="plans_info_item">
                    <span>{{ translateWord('Todos os planos incluem') }}</span>
                </div>

                <div class="plans_info_item">
                    <div class="plans_info_item_icon">
                        <i class="icon icon-check"></i>
                    </div>
                    <span class="plans_info_item_name">{{ translateWord('Hospedagem site seguro') }}</span>
                </div>

                <div class="plans_info_item">
                    <div class="plans_info_item_icon">
                        <i class="icon icon-check"></i>
                    </div>
                    <span class="plans_info_item_name">{{ translateWord('Email profissional @seudominio.com') }}</span>
                </div>

                <div class="plans_info_item">
                    <div class="plans_info_item_icon">
                        <i class="icon icon-check"></i>
                    </div>
                    <span class="plans_info_item_name">{{ translateWord('24/7 Sucesso do cliente') }}</span>
                </div>
            </div>
        </div>

        <div class="container containerAdapter plans_details">
            <div class="content">
                <Loading v-if="loading.plans" class="loading"></Loading>

                <template v-if="['normal', 'trial'].includes(planTypeView) && !loading.plans">
                    <div v-for="plan in plans" v-if="plan.slug.search('wp') !== 0" class="plans_details_item">
                        <div v-if="plan.recommended" class="plans_details_item_popular">
                            <img class="plans_details_item_popular_img" :src="require('../assets/imagem/popular.svg')">
                            <p class="plans_details_item_popular_text">{{ translateWord('Mais Popular!') }}</p>
                        </div>

                        <h3 class="plans_details_item_title">{{ plan.title }}</h3>
                        <p v-if="plan.subTitle" class="plans_details_item_subTitle">{{ plan.subTitle }}</p>
                        
                        <div v-if="plan.isCoupon && plan.coupon.period == 'FIRST_MONTH'" class="plans_details_item_priceCoupon">
                            <p class="plans_details_item_priceCoupon_del">
                                <del>{{ plan.symbol }}{{ amountSplit(plan.price) }}</del> {{ translateWord('à venda') }}
                            </p>

                            <div class="plans_details_item_priceCoupon_content">
                                <span class="plans_details_item_price_symbol">{{ plan.symbol }}</span>
                                <span class="plans_details_item_price_amount" :class="{plans_details_item_price_amountSmallsize: parseInt(plan.price).toString().length >= 6}">
                                    <strong>{{ amountSplit(plan.coupon.amount) }}</strong>

                                    <span v-if="plan.coupon.period == 'FIRST_MONTH'" class="plans_details_item_price_amount_cents"> 
                                        <p><strong>{{ translateWord('Primeiro') }}</strong></p>
                                        <p>{{ translateWord('Mes') }}</p>
                                    </span>
                                </span>
                                <p class="plans_details_item_price_desc">{{ plan.description }}</p>
                            </div>
                        </div>

                        <div v-else class="plans_details_item_price">
                            <span class="plans_details_item_price_symbol">{{ plan.symbol }}</span>
                            <span class="plans_details_item_price_amount" :class="{plans_details_item_price_amountSmallsize: parseInt(plan.price).toString().length >= 6}">
                                <strong>{{ amountSplit(plan.price) }}</strong>
                                <span class="plans_details_item_price_amount_cents"> /mo</span>
                            </span>
                            <p class="plans_details_item_price_desc">{{ plan.description }}</p>
                        </div>

                        <a :href="plan.link" class="btn2 btn_blue plans_details_item_action">
                            {{ isUrlPlansTrial ? translateWord('TESTE GRATUITO DE 14 DIAS') : translateWord('ASSINAR AGORA') }}
                        </a>

                        <p class="plans_details_item_actionCoupon" v-if="plan.isCoupon && plan.coupon.period == 'FIRST_MONTH'"> {{ translateWord('Renovar por {0}{1}', [
                            plan.symbol,
                            amountSplit(plan.price)
                        ]) }}/mo</p>

                        <div class="plans_details_item_desc">
                            <p class="plans_details_item_desc_title">{{ translateWord('Incluidos') }}</p>

                            <p v-for="included in plan.includes" class="plans_details_item_desc_item" :class="{deleted: !included.check}">
                                <template v-if="included.check">
                                    <img class="plans_details_item_desc_item_img" :src="require('../assets/imagem/verify.svg')">
                                    <span> {{ included.name }}</span>
                                </template>

                                <template v-else>
                                    <span class="icon icon-x-circle"></span>
                                    <span><del>{{ included.name }}</del></span>
                                </template>                                
                            </p>
                        </div>

                        <div class="plans_details_item_desc">
                            <p class="plans_details_item_desc_title">{{ translateWord('Ferramentas') }}</p>
                            
                            <p class="plans_details_item_desc_complements">{{ plan.infoComplements }}</p>

                            <p v-for="info in plan.info" class="plans_details_item_desc_item plans_details_item_desc_itemTools">
                                {{ info }}
                            </p>
                        </div>
                    </div>
                </template>

                <template v-if="planTypeView == 'wp' && !loading.plans">
                    <div v-for="plan in plans" v-if="plan.slug.search('wp') !== -1" class="plans_details_item">
                        <div v-if="plan.recommended" class="plans_details_item_popular">
                            <img class="plans_details_item_popular_img" :src="require('../assets/imagem/popular.svg')">
                            <p class="plans_details_item_popular_text">Most Popular!</p>
                        </div>
                        
                        <h3 class="plans_details_item_title">{{ plan.title }}</h3>
                        <p v-if="plan.subTitle" class="plans_details_item_subTitle">{{ plan.subTitle }}</p>

                        <div v-if="plan.isCoupon && plan.coupon.period == 'FIRST_MONTH'" class="plans_details_item_priceCoupon">
                            <p class="plans_details_item_priceCoupon_del">
                                <del>{{ plan.symbol }}{{ amountSplit(plan.price) }}</del> {{ translateWord('à venda') }}
                            </p>

                            <div class="plans_details_item_priceCoupon_content">
                                <span class="plans_details_item_price_symbol">{{ plan.symbol }}</span>
                                <span class="plans_details_item_price_amount" :class="{plans_details_item_price_amountSmallsize: parseInt(plan.price).toString().length >= 6}">
                                    <strong>{{ amountSplit(plan.coupon.amount) }}</strong>

                                    <span v-if="plan.coupon.period == 'FIRST_MONTH'" class="plans_details_item_price_amount_cents"> 
                                        <p><strong>First</strong></p>
                                        <p>Month</p>
                                    </span>
                                </span>
                                <p class="plans_details_item_price_desc">{{ plan.description }}</p>
                            </div>
                        </div>

                        <div v-else class="plans_details_item_price">
                            <span class="plans_details_item_price_symbol">{{ plan.symbol }}</span>
                            <span class="plans_details_item_price_amount" :class="{plans_details_item_price_amountSmallsize: parseInt(plan.price).toString().length >= 6}">
                                <strong>{{ amountSplit(plan.price) }}</strong>
                                <span class="plans_details_item_price_amount_cents"> /mo</span>
                            </span>

                            <p class="plans_details_item_price_desc">{{ plan.description }}</p>
                        </div>

                        <a :href="plan.link" class="btn2 btn_blue plans_details_item_action">{{ translateWord('ASSINAR AGORA') }}</a>

                        <p class="plans_details_item_actionCoupon" v-if="plan.isCoupon && plan.coupon.period == 'FIRST_MONTH'"> {{ translateWord('Renovar por {0}{1}', [
                            plan.symbol,
                            amountSplit(plan.price)
                        ]) }}/mo</p>

                        <div class="plans_details_item_desc">
                            <p class="plans_details_item_desc_title">{{ translateWord('Incluidos') }}</p>

                            <p v-for="included in plan.includes" class="plans_details_item_desc_item" :class="{deleted: !included.check}">
                                <template v-if="included.check">
                                    <img class="plans_details_item_desc_item_img" :src="require('../assets/imagem/verify.svg')">
                                    <span> {{ included.name }}</span>
                                </template>

                                <template v-else>
                                    <span class="icon icon-x-circle"></span>
                                    <span><del>{{ included.name }}</del></span>
                                </template>                                
                            </p>
                        </div>

                        <div class="plans_details_item_desc">
                            <p class="plans_details_item_desc_title">{{ translateWord('Ferramentas') }}</p>
                            
                            <p class="plans_details_item_desc_desc">{{  }}</p>
                            <p v-for="info in plan.info" class="plans_details_item_desc_item plans_details_item_desc_itemTools">
                                {{ info }}
                            </p>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div class="container plans_security">
            <div class="content">
                <div class="plans_security_item">
                    <div class="plans_security_item_info">
                        <p class="plans_security_item_info_text">{{ translateWord('ACEITAMOS TODAS AS BANDEIRAS') }}</p>

                        <img :src="require('../assets/card-brands/news/visa.png')" alt="" class="plans_security_item_info_image">
                        <img :src="require('../assets/card-brands/news/master.png')" alt="" class="plans_security_item_info_image">
                        <img :src="require('../assets/card-brands/news/amex.png')" alt="" class="plans_security_item_info_image">
                        <img :src="require('../assets/card-brands/news/paypal.png')" alt="" class="plans_security_item_info_image">
                        <img :src="require('../assets/card-brands/news/amazon-pay.png')" alt="" class="plans_security_item_info_image">
                        <img :src="require('../assets/card-brands/news/boleto.png')" alt="" class="plans_security_item_info_image">
                        <img :src="require('../assets/card-brands/news/google-pay.png')" alt="" class="plans_security_item_info_image">
                        <img :src="require('../assets/card-brands/news/apple-pay.png')" alt="" class="plans_security_item_info_image">
                        <img :src="require('../assets/card-brands/news/pix.png')" alt="" class="plans_security_item_info_image">
                    </div>
                </div>

                <div class="plans_security_item">
                    <img class="plans_security_item_image" :src="require('../assets/imagem/ssl.png')" alt="">

                    <div class="plans_security_item_info plans_security_item_infoSecure">
                        <p class="plans_security_item_info_text">{{ translateWord('Seguro, Criptografado') }}</p>
                        <p class="plans_security_item_info_text">{{ translateWord('Pagamentos SSL') }}</p>
                    </div>
                </div>

                <div class="plans_security_item">
                    <img class="plans_security_item_image" :src="require('../assets/imagem/money.png')" alt="">

                    <div class="plans_security_item_info plans_security_item_infoGuarantee">
                        <p class="plans_security_item_info_text">{{ translateWord('Dinheiro para 30 dias') }}</p>
                        <p class="plans_security_item_info_text">{{ translateWord('Garantia de devolução') }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="container superCharge">
            <p class="superCharge_title">{{ translateWord('Já construiu seu site em outro lugar? Obtenha o Builderall em seu site existente gratuitamente!') }}</p>
            
            <div class="content">
                <div class="superCharge_logo">
                    <img :src="require('../assets/imagem/supercharge.png')" class="superCharge_logo_image">
                    
                    <h2 class="superCharge_logo_namePlan">Bundle</h2>
                    
                    <p class="superCharge_logo_description">{{ translateWord('Com apenas uma linha de código adicionada a qualquer site, em qualquer plataforma, você obtém acesso fácil a esse poderoso conjunto de ferramentas.') }}</p>
                </div>

                <div class="superCharge_action">
                    <h2 class="superCharge_action_name">{{ translateWord('Grátis') }}</h2>

                    <a class="superCharge_action_name_action btn2 btn_blue" :href="superChargeLinkBundlePlan">{{ translateWord('Inscreva-se Agora') }}</a>

                    <p class="superCharge_action_description">{{ translateWord('Cartão de crédito necessário') }}</p>
                </div>

                <div class="superCharge_tools">
                    <p class="superCharge_tools_title">{{ translateWord('Ferramentas') }}</p>

                    <span class="superCharge_tools_tool">{{ translateWord('Social Proof') }}</span>
                    <span class="superCharge_tools_tool">{{ translateWord('Notificações de Navegador') }}</span>
                    <span class="superCharge_tools_tool">{{ translateWord('Live Chat') }}</span>
                    <span class="superCharge_tools_tool">{{ translateWord('Exit Pop-up') }}</span>
                    <span class="superCharge_tools_tool">{{ translateWord('Meta Autoresponder') }}</span>
                </div>
            </div>
        </div>

        <div class="container plans_toolsAndFeature">
            <div class="content">
                <Resources
                    :name="translateWord('Recursos')"
                    :headers="resources.nameTools"
                    :resources="resources.items"
                />

                <Loading v-if="loading.features" class="loading"></Loading>
            </div>
        </div>

        <div class="container plans_questions">
            <div class="content">

                <div class="plans_questions_container">
                    <h1 class="plans_questions_title">{{ translateWord('Perguntas frequentes') }}<span>.</span></h1>

                    <div class="plans_questions_questions">
                        <div v-for="question in questions" class="plans_questions_questions_question">
                            <p @click="question.visible = !question.visible" class="plans_questions_questions_question_text">{{ question.text }}</p>
                            <p v-if="question.visible" class="plans_questions_questions_question_reply">{{ question.reply }}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="container plans_footer">
            <div class="content">

                <div class="plans_footer_block plans_footer_blockAbout">
                    <p class="plans_footer_blockAbout_title">
                        {{ translateWord('SOBRE BUILDERALL®') }}
                    </p>

                    <p class="plans_footer_blockAbout_desc">
                        {{ translateWord('Builderall® é uma marca registrada da Cloud Builder LLC. Todos os elementos, imagens e conteúdos deste site são © 2023 Cloud Builder LLC.') }}
                    </p>
                </div>

                <div class="plans_footer_block plans_footer_blockMenu1">
                    <li>
                        <a :href="`${links.home}${allParams()}`">{{ translateWord('Home') }}</a>
                    </li>

                    <li>
                        <a :href="`${allParams()}`">{{ translateWord('Planos') }}</a>
                    </li>

                    <li>
                        <a :href="`${links.about}${allParams()}`">{{ translateWord('Sobre Nós') }}</a>
                    </li>

                    <li>
                        <a :href="`${links.affiliate}${allParams()}`">{{ translateWord('Afiliados') }}</a>
                    </li>

                    <li>
                        <a :href="`${links.login}${allParams()}`">{{ translateWord('Login') }}</a>
                    </li>

                </div>

                <div class="plans_footer_block plans_footer_blockMenu2">
                    <li>
                        <a :href="`${links.ourTools}${allParams()}`">{{ translateWord('Nossas Ferramentas') }}</a>
                    </li>

                    <li>
                        <a :href="`${links.elements}${allParams()}`">{{ translateWord('Elementos') }}</a>
                    </li>

                    <li>
                        <a :href="`${links.partners}${allParams()}`">{{ translateWord('Parceiros') }}</a>
                    </li>
                </div>

                <div class="plans_footer_block plans_footer_blockMenu3">
                    <li>
                        <a :href="`${links.legalInformation}${allParams()}`">{{ translateWord('Informações Legais') }}</a>
                    </li>

                    <li>
                        <a :href="`${links.termsOfUse}${allParams()}`">{{ translateWord('Termos de Uso') }}</a>
                    </li>
                </div>

                <div class="plans_footer_block plans_footer_blockMidiaSocial">
                    <div class="plans_footer_blockMidiaSocial_icons">
                        <a :href="links.facebook" class="icon icon-facebook"></a>
                        <a :href="links.instagram" class="icon icon-instagram"></a>
                    </div>

                    <p v-html="translateWord('Nós gostariamos de ouvir de você! Nos envie um e-mail para <b>{0}</b> com quaisquer perguntas ou comentários.', [this.emailContactBuilderall])" class="plans_footer_blockMidiaSocial_desc"></p>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import cookie from 'js-cookie';
import Helper from '../assets/js/helper';
import Loading from '../components/Loading';
import Resources from '../components/Pages/Resources';
import Banner from '../components/Banner';
import PixelAnalytics from '../assets/js/PixelAnalytics';

export default {
    name: "Plans",
    data(){
        return {
            planTypeView: 'normal',
            defaultCountryPrice: 'us',
            loadTranslation: false,
            isUrlPlansTrial: false,
            plans: [],
            plansFilter: [],
            features: [],
            questions: [],
            countryUser: null,
            plansChargeCountry: [],
            countryPrices: [],
            loading: {
                plans: true,
                features: true
            },
            viewMenuResponsive: false,
            links: {},
            resources: {
                nameTools: [],
                items: []
            },
            emailContactBuilderall: Helper.getContactEmailBuilderall(),
            isSolution: false,
            isTools: false,
            superChargeLinkBundlePlan: ''
        }
    },
    async created(){
        if(this.$route.path == '/plans-trial'){
            this.isUrlPlansTrial = true;
        }

        await this.verifyTranslation();

        this.pixelPage();

        this.links = this.linksPage('plans');

        PixelAnalytics.createEventPixel('tt', 'ViewPlans', {});

        this.plans = [
            {
                typePlan: 'normal',
                title: 'Core',
                subTitle: this.translateWord('Coloque seu negócio online com o básico que você precisa.'),
                price: 9999,
                symbol: '$',
                includedName: 'All Tools Included',
                slug: 'core-account',
                link: `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}/payment/core-account${this.allParams()}`,
                includes: [
                    '1 User',
                    '1 Publicação Website',
                    '2 Pro Mailboxes',
                    '15 GB Storage'
                ],
                infoComplements: '',
                info: [
                    this.translateWord('Construtor de sites'),
                    this.translateWord('Notificações de Navegador'),
                    this.translateWord('DNS Manager'),
                    this.translateWord('Email Profissional'),
                    this.translateWord('URL Shortener')
                ],
                imageTools: require('../assets/imagem/tool_core.png'),
                tools: ['Teste 1', 'Teste 2'],
                couponSuper: 'SUPER'
            },
            {
                typePlan: 'normal',
                title: 'Essentials',
                subTitle: this.translateWord('Todas as ferramentas que você precisa para começar a expandir seu negócio.'),
                price: 9999,
                symbol: '$',
                includedName: 'All Tools + Templates + Funnels',
                slug: 'essentials-plan',
                link: `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}/payment/essentials-plan${this.allParams()}`,
                includes: [
                    '1 User',
                    '1 Publicação Website',
                    '2 Pro Mailboxes',
                    '15 GB Storage'
                ],
                infoComplements: this.translateWord('Todas as ferramentas Core +'),
                info: [
                    this.translateWord('MaillingBoss'),
                    this.translateWord('Booking'),
                    this.translateWord('Social Proof Pop-Up'),
                    this.translateWord('Sitebot'),
                    this.translateWord('Wordpress')
                ],
                imageTools: require('../assets/imagem/tool_essentials.png'),
                tools: ['Teste 1'],
                couponSuper: 'SUPER1'
            },
            {
                typePlan: 'normal',
                title: 'Advanced',
                subTitle: this.translateWord('Leve seu marketing e vendas para o próximo nível.'),
                price: 9999,
                symbol: '$',
                recommended: true,
                includedName: 'Builderall Builder + Mailingboss',
                slug: 'advanced-plan',
                link: `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}/payment/advanced-plan${this.allParams()}`,
                includes: [
                    '1 User',
                    '1 Publicação Website',
                    '2 Pro Mailboxes',
                    '15 GB Storage'
                ],
                infoComplements: this.translateWord('Todas as ferramentas Essential +'),
                info: [
                    this.translateWord('Construtor de Funis'),
                    this.translateWord('Central de WhatsApp'),
                    this.translateWord('Mensagens SMS'),
                    this.translateWord('IG/Messenger Chatbot'),
                    this.translateWord('CRM'),
                    this.translateWord('eLearning'),
                    this.translateWord('Team Access')
                ],
                imageTools: require('../assets/imagem/tool_advanced.png'),
                tools: ['Teste 1', 'Teste 2', 'Teste 3'],
                couponSuper: 'SUPER2'
            },
            {
                typePlan: 'normal',
                title: 'Premium',
                subTitle: this.translateWord('Tudo que você precisa para lançar e expandir seu negócio.'),
                price: 9999,
                symbol: '$',
                includedName: 'All Tools Included',
                slug: 'premium-plus-plan',
                link: `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}/payment/premium-plus-plan${this.allParams()}`,
                includes: [
                    '1 User',
                    '1 Publicação Website',
                    '2 Pro Mailboxes',
                    '15 GB Storage'
                ],
                infoComplements: this.translateWord('Todas as ferramentas Advanced +'),
                info: [
                    this.translateWord('Video Hosting'),
                    this.translateWord('Webinar Builder'),
                    this.translateWord('Quiz Builder'),
                    this.translateWord('Builderall Plume')
                ],
                imageTools: require('../assets/imagem/tool_premium.png'),
                tools: ['Teste 1', 'Teste 2'],
                couponSuper: 'SUPER3'
            },
            {
                typePlan: 'wordpress',
                title: 'WP Premium',
                subTitle: this.translateWord('Tudo que você precisa para lançar e expandir seu negócio.'),
                price: 9999,
                symbol: '$',
                includedName: 'All Tools Included',
                slug: 'wp-premium-plus-plan',
                link: `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}/payment/wp-premium-plus-plan${this.allParams()}`,
                includes: [
                    '1 User',
                    '1 Publicação Website',
                    '2 Pro Mailboxes',
                    '15 GB Storage'
                ],
                info: [
                    this.translateWord('Criador de Conteúdo IA'),
                    this.translateWord('Advanced, mais:'),
                    this.translateWord('Criador do curso'),
                    this.translateWord('Hospedagem de vídeo')
                ],
                imageTools: require('../assets/imagem/tool_premium.png'),
                couponSuper: 'SUPER3'
            },
            {
                typePlan: 'wordpress',
                title: 'WP Advanced',
                subTitle: this.translateWord('Leve seu marketing e vendas para o próximo nível.'),
                price: 9999,
                symbol: '$',
                recommended: true,
                includedName: 'Builderall Builder + Mailingboss',
                slug: 'wp-advanced-plan',
                link: `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}/payment/wp-advanced-plan${this.allParams()}`,
                includes: [
                    '1 User',
                    '1 Publicação Website',
                    '2 Pro Mailboxes',
                    '15 GB Storage'
                ],
                info: [
                    this.translateWord('Criador de Conteúdo IA'),
                    this.translateWord('Essentials, mais:'),
                    this.translateWord('Construtor de funil'),
                    this.translateWord('Todas as ferramentas de lançamento')
                ],
                imageTools: require('../assets/imagem/tool_advanced.png'),
                couponSuper: 'SUPER2'
            },
            {
                typePlan: 'wordpress',
                title: 'WP Essentials',
                subTitle: this.translateWord('Todas as ferramentas que você precisa para começar a expandir seu negócio.'),
                price: 9999,
                symbol: '$',
                includedName: 'All Tools + Templates + Funnels',
                slug: 'wp-essentials-plan',
                link: `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}/payment/wp-essentials-plan${this.allParams()}`,
                includes: [
                    '1 User',
                    '1 Publicação Website',
                    '2 Pro Mailboxes',
                    '15 GB Storage'
                ],
                info: [
                    this.translateWord('Criador de Conteúdo IA'),
                    this.translateWord('Construtor de sites'),
                    this.translateWord('Automação de e-mail'),
                    this.translateWord('Bate-papo no site')
                ],
                imageTools: require('../assets/imagem/tool_essentials.png'),
                couponSuper: 'SUPER1'
            }
        ];

        if(this.isUrlPlansTrial){
            this.plans = [
                {
                    typePlan: 'trial',
                    title: 'Premium',
                    subTitle: this.translateWord('Tudo que você precisa para lançar e expandir seu negócio.'),
                    price: 9999,
                    symbol: '$',
                    includedName: 'All Tools Included',
                    slug: 'premium-plus-plan-trial',
                    link: `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}/payment/premium-plus-plan-trial${this.allParams()}`,
                    includes: [
                        '1 User',
                        '1 Publicação Website',
                        '2 Pro Mailboxes',
                        '15 GB Storage'
                    ],
                    info: [
                        this.translateWord('Criador de Conteúdo IA'),
                        this.translateWord('Advanced, mais:'),
                        this.translateWord('Criador do curso'),
                        this.translateWord('Hospedagem de vídeo')
                    ],
                    imageTools: require('../assets/imagem/tool_premium.png'),
                    tools: ['Teste 1', 'Teste 2']
                },
                {
                    typePlan: 'trial',
                    title: 'Advanced',
                    subTitle: this.translateWord('Leve seu marketing e vendas para o próximo nível.'),
                    price: 9999,
                    symbol: '$',
                    recommended: true,
                    includedName: 'Builderall Builder + Mailingboss',
                    slug: 'advanced-plan-trial',
                    link: `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}/payment/advanced-plan-trial${this.allParams()}`,
                    includes: [
                        '1 User',
                        '1 Publicação Website',
                        '2 Pro Mailboxes',
                        '15 GB Storage'
                    ],
                    info: [
                        this.translateWord('Criador de Conteúdo IA'),
                        this.translateWord('Essentials, mais:'),
                        this.translateWord('Construtor de funil'),
                        this.translateWord('Todas as ferramentas de lançamento')
                    ],
                    imageTools: require('../assets/imagem/tool_advanced.png'),
                    tools: ['Teste 1', 'Teste 2', 'Teste 3']
                },
                {
                    typePlan: 'trial',
                    title: 'Essentials',
                    subTitle: this.translateWord('Todas as ferramentas que você precisa para começar a expandir seu negócio.'),
                    price: 9999,
                    symbol: '$',
                    includedName: 'All Tools + Templates + Funnels',
                    slug: 'essentials-plan-trial',
                    link: `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}/payment/essentials-plan-trial${this.allParams()}`,
                    includes: [
                        '1 User',
                        '1 Publicação Website',
                        '2 Pro Mailboxes',
                        '15 GB Storage'
                    ],
                    info: [
                        this.translateWord('Criador de Conteúdo IA'),
                        this.translateWord('Construtor de sites'),
                        this.translateWord('Automação de e-mail'),
                        this.translateWord('Bate-papo no site')
                    ],
                    imageTools: require('../assets/imagem/tool_essentials.png'),
                    tools: ['Teste 1']
                },
                {
                    typePlan: 'trial',
                    title: 'Core',
                    subTitle: this.translateWord('Coloque seu negócio online com o básico que você precisa.'),
                    price: 9999,
                    symbol: '$',
                    includedName: 'All Tools + Templates + Funnels',
                    slug: 'core-account-trial',
                    link: `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}/payment/core-account-trial${this.allParams()}`,
                    includes: [
                        '1 User',
                        '1 Publicação Website',
                        '2 Pro Mailboxes',
                        '15 GB Storage'
                    ],
                    info: [
                        this.translateWord('Criador de Conteúdo IA'),
                        this.translateWord('Construtor de sites')
                    ],
                    imageTools: require('../assets/imagem/tool_core.png'),
                    tools: ['Teste 1']
                }
            ];
        }

        await this.listCountryPrice();
        
        await Promise.all(this.plans.map(async plan => {
            let planCharge = this.countryPrices.find(planCharge => planCharge.plan.slug == plan.slug);

            if(planCharge == undefined) return;

            plan.id = planCharge.plan.id;
            plan.price = parseFloat(planCharge.currency.value) * 100;
            plan.symbol = planCharge.currency.symbol;
            plan.tools = planCharge.plan.tools.filter(tool => !['WhatsApp Launch Manager', 'BuilderallZap'].includes(tool));
            plan.isCoupon = false;
            plan.coupon = {};

            let couponCodeUrl = this.$route.query.coupon !== undefined ? this.$route.query.coupon : false;

            if(couponCodeUrl){
                couponCodeUrl = couponCodeUrl.toUpperCase();

                if(['SUPER', 'SUPER1', 'SUPER2', 'SUPER3'].includes(couponCodeUrl)){
                    let hasCouponSuper = Helper.getNewNameCouponSuper(plan.couponSuper, plan.slug);

                    if(hasCouponSuper.hasNewNameCoupon) couponCodeUrl = hasCouponSuper.newCoupon;
                }

                await this.validateCoupon({
                    couponUser: couponCodeUrl, 
                    isLoadingCheckout: true,
                    isPagePlan: true,
                    planId: plan.id
                });
            }
            
            if(couponCodeUrl && this.coupon.isValid){
                plan.isCoupon = true;
                let amountCoupon = Helper.calcDiscount(plan.price / 100, this.coupon.value, this.coupon.type) * 100;
                
                plan.coupon.amount = amountCoupon;
                plan.coupon.amountApplied = plan.price - amountCoupon;
                plan.coupon.period = this.coupon.period;

                this.clearCoupon();
            }

            if(planCharge.plan.front_resource_items){
                let includesInfo = Object.values(planCharge.plan.front_resource_items);

                //Adicionar os check(fake) false para ultimo e remove o recurso de domains
                includesInfo.forEach((v, i) => {
                    if(v.fake) {
                        let item = includesInfo.splice(i, 1);
                        includesInfo.push(item[0]);
                    }
                });

                plan.includes = [];

                includesInfo.forEach(included => {
                    if(['domains', 'storage', 'subscribers', 'subdomains'].includes(included.resource)) return;

                    let qtd = Helper.convertNumber(included.quantity);

                    if(included.quantity == 0) qtd = '';

                    if(included.quantity == 999999) qtd = this.translateWord('Ilimitado');

                    plan.includes.push({
                        name: `${qtd} ${included.title}`,
                        check: !included.fake
                    })
                });
            }

            return plan;
        })); 

        this.loading.plans = false;

        this.questions = [
            {
                text: this.translateWord('Existe a possibilidade de adicionar produtos ou fazer upgrades?'),
                reply: this.translateWord('Sim! Nós temos opções adicionais e upgrades disponíveis exclusivos para potencializar seu negócio.'),
                visible: false
            },
            {
                text: this.translateWord('É possível importar meu conteúdo para a plataforma?'),
                reply: this.translateWord('Como a nossa plataforma possuí uma arquitetura exclusiva e única para maximizar performance e carregamento de nossos websites, arquivos externos não são compatíveis com o nosso sistema. Para nossos novos membros que estão migrando para a Builderall, disponibilizamos diversos templates para criar seu website com facilidade.'),
                visible: false
            },
            {
                text: this.translateWord('Quando posso fazer um upgrade do meu plano?'),
                reply: this.translateWord('Você pode realizar um upgrade do seu plano a qualquer momento, basta contactar nosso time de suporte através do chat online ou via ticket!'),
                visible: false
            }
        ];

        this.isUrlPlansTrial ? this.changePlanType('trial') : this.changePlanType('normal');

        //Sort the display of plans in ascending order. Only for trial plans
        this.plans.sort((a, b) => a.price - b.price);

        this.superChargeLinkBundlePlan = `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}/payment/bundle-plan${this.allParams()}`;
        this.loading.features = false;
    },
    computed: {
        ...mapState([
            'localeUser',
            'endpointOffice',
            'coupon'
        ]),
        ...mapGetters([
            'translateWord',
            'getAllParamsUrl',
            'detectReferrer',
            'linksPage'
        ]),
        listBanners(){
            return {
                en: {
                    desktop: require('../assets/30off/en/website.jpg'),
                    mobile: require('../assets/30off/en/mobile.jpg')
                },
                pt: {
                    desktop: require('../assets/30off/pt/website.jpg'),
                    mobile: require('../assets/30off/pt/mobile.jpg')
                },
                es: {
                    desktop: require('../assets/30off/es/website.jpg'),
                    mobile: require('../assets/30off/es/mobile.jpg')
                }
            };
        }
    },
    methods: {
        ...mapActions([
            'getTranslation',
            'getCountryPrices',
            'verifyPixelAnalytics',
            'verifyPixelBuilderall',
            'verifyPixelAnalyticsParent',
            'validateCoupon'
        ]),
        ...mapMutations([
            'enabledFullScreenLoading',
            'clearCoupon'
        ]),
        async listCountryPrice(){
            this.countryPrices = await this.getCountryPrices(this.$route.query['country'] || null);

            for await(let plan of this.plans){
                let planCharge = this.countryPrices.find(planCharge => planCharge.plan.slug == plan.slug);

                if(planCharge == undefined){
                    this.countryPrices = await this.getCountryPrices(this.defaultCountryPrice);
                    return;
                }
            }
        },
        allParams(){
            let params = this.getAllParamsUrl(false);

            //Pegar aid pelo #aid quando houver
            if(Helper.getParamHash('aid')){
                params = `${params}#aid=${Helper.getParamHash('aid')}`;
            }

            return params;
        },
        amountSplit(amount, isDecimal){
            let amountFormat = (amount / 100).toFixed(2);

            return isDecimal ? amountFormat.split('.')[1] : amountFormat.split('.')[0];

        },
        changePlanType(type){
            this.planTypeView = type;

            if(this.planTypeView == 'normal') this.mountRecourses('normal');

            if(this.planTypeView == 'trial') this.mountRecourses('trial');

            if(this.planTypeView == 'wp') this.mountRecourses('wordpress');
        },
        async verifyTranslation(){
            let lang = Helper.convertSigla(Helper.getLangBrowser());

            //Temporario ate traduzir o polones
            lang = lang == 'pl' ? 'en' : lang;

            await this.getTranslation({
                sigla: lang,
                category: 'plans'
            });

            this.loadTranslation = true;
        },
        mountRecourses(planType){
            this.createResources(planType);
            
            for(let i = 1; i <= this.plansFilter.length; i++){
                this.verifyAccessTool(this.plansFilter[i - 1].tools, i);
            }

            this.tempOrderList();
        },
        createResources(planType){
            this.plansFilter = this.plans.filter(plan => plan.typePlan == planType);

            //Sort the display of plans in ascending order. Only for trial plans
            this.plansFilter.sort((a, b) => a.price - b.price);

            let tools = [];
            let titles = [];

            this.plansFilter.map(plan => tools.push(...plan.tools));
            this.plansFilter.map(plan => titles.push(plan.title));

            this.resources.nameTools = titles;
            this.resources.items = tools.filter((name, index, arr) => arr.indexOf(name) === index).map(name => ({
                name: name,
                checks: this.plansFilter.map(r => false) //Marcar todas como false para iniciar o resources
            }));

            // //Adiciona feature se selecionar para exibir plano wordpress
            if(this.planTypeView == 'wp') {
                this.resources.items.unshift({name: this.translateWord('Servidor em nuvem (Wordpres & WooCommerce)'), checks: [true, true, true]})
            }
        },
        verifyAccessTool(toolsCheck, indiceCheck){
            indiceCheck = --indiceCheck;

            toolsCheck.forEach(nameUrl => {
                this.resources.items.forEach((nameResource, index) => {
                    if(nameUrl == nameResource.name){
                        this.resources.items[index].checks[indiceCheck] = true
                    }
                })
            });
        },
        linkEnterprise(){
            let lang = Helper.convertSigla(Helper.getLangBrowser());
            let link = null;

            switch(lang){
                case 'en': link = 'https://builderall.com/enterprise'; break;
                case 'pt': link = 'https://br.builderall.com/enterprise'; break;
                case 'es': link = 'https://es.builderall.com/enterprise'; break;
                case 'it': link = 'https://it.builderall.com/enterprise'; break;
                case 'de': link = 'https://builderall.de/enterprise'; break;
                case 'fr': link = 'https://fr.builderall.com/enterprise'; break;

                default: link = 'https://builderall.com/enterprise'; break;
            }

            return link;
        },
        tempOrderList(){
            let list = JSON.parse(JSON.stringify(this.resources.items));
            let newList = [];

            list.forEach(item => {
                let totalTrue = 4;
                let totalFound = 0;

                item.checks.forEach(value => value ? totalFound++ : '')

                if(totalFound == totalTrue) newList.push(item);
            });

            list.forEach(item => {
                let totalTrue = 3;
                let totalFound = 0;

                item.checks.forEach(value => value ? totalFound++ : '')

                if(totalFound == totalTrue) newList.push(item);
            });

            list.forEach(item => {
                let totalTrue = 2;
                let totalFound = 0;

                item.checks.forEach(value => value ? totalFound++ : '')

                if(totalFound == totalTrue) newList.push(item);
            });

            list.forEach(item => {
                let totalTrue = 1;
                let totalFound = 0;

                item.checks.forEach(value => value ? totalFound++ : '')

                if(totalFound == totalTrue) newList.push(item);
            });

            this.resources.items = newList;
        },
        pixelPage(){
            let aid = Helper.getAidOffice() || this.$route.query.aid || cookie.get('IdPaiOffice') || Helper.builderallAccounts.internetMarketingLLC_10;
            let subdomain = this.$route.query.sd || null;

            if(subdomain === null) {
                let urlWithHash = new URLSearchParams(decodeURIComponent(location.hash.replace('#', '')));

                if(urlWithHash.get('sd')) subdomain = urlWithHash.get('sd');
            }

            this.$route.query['pixel-event'] ? this.verifyPixelAnalyticsParent() : this.verifyPixelAnalytics({aid, subdomain});

            this.verifyPixelBuilderall();

        },
        subenuActive(submenu){
            if(submenu == 'solutions'){
                this.isSolution = !this.isSolution;
                this.isTools = false;
            }

            if(submenu == 'tools'){
                this.isSolution = false;
                this.isTools = !this.isTools;
            }
        },
        hiddenSubmenu(){
            this.isSolution = false;
            this.isTools = false;
        }
    },
    components: {
        Loading,
        Resources,
        Banner
    }
}
</script>

<style lang="scss" scoped>
    @import '../scss/mixins';

    .arrow {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        margin-left: 8px;
        margin-bottom: 2px;
    }

    .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    .left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }

    .up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }

    .down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }

    .loading{
        margin: auto;
    }
    .plans{
        font-family: Montserrat;
        overflow-x: hidden;

        @include media(sm,md){
            .content{
                margin: auto 30px;
            }
        }
    }

    .plans_banner{
        margin-bottom: -2px;
    }

    .plans_login{
        background: rgba(217, 217, 217, 0.996);

        & .content{
            display: flex;
            justify-content: flex-end;
        }
    }
    .plans_login_item{
        border-left: 1px solid var(--black);
        font-size: 0.7em;
        padding: 8px 20px;
        font-weight: bold;

        &:hover:not(.plans_login_socialMedia){
            background: #d0d0d0;
        }

        &:first-child{
            border-left: none;
        }
    }
    .plans_login_socialMedia,
    .plans_footer_blockMidiaSocial_icons{
        & .icon{
            color: #d4d4d4;
            background: #858585;
            border-radius: 50%;
            margin-left: 5px;
            padding: 4px 5px;
            font-size: 0.9em;
        }
    }
    .plans_login_support{
        color: rgba(132, 132, 132, 0.996);

        &:hover{
            color: rgba(37, 96, 223, 0.996);;
        }
    }
    .plans_login_login{
        color: rgba(37, 96, 223, 0.996);
    }

    .plans_menu{
        background: rgba(230, 230, 230, 0.996);

        & .content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 22px 0px;
            position: relative;
        }

        & .menuResponsive{
            display: none;
        }
        
        & .plans_menu_menuListResponsive{
            display: none;
        }

        @include media(sm,md){
            .plans_menu_actions a{
                display: none;
            }

            .plans_menu_actions .menuResponsive{
                display: block;
                font-size: 1.9em;
                color: var(--blue);
                cursor: pointer;
            }

            .plans_menu_menuListResponsive{
                display: flex;
                flex-direction: column;
                text-align: center;
                margin: auto 30px;

                & .plans_menu_menuList_subList{
                    width: 100%;
                }
            }
        }
    }
    .plans_menu_logo{
        width: 160px;
    }
    .plans_menu_menuList{
        font-size: 0.9em;

        @include media(sm,md){
            display: none;
        }
    }
    .plans_menu_menuList_link{
        display: inline;
        color: var(--black);
        padding: 15px 20px;
        font-weight: 600;
        cursor: pointer;

        &.active, &:hover{
            background: #FFFFFF73;
            border-radius: 10px;
        }
        
        @include media(sm,md){
            position: relative;
        }
    }
    .plans_menu_menuList_subList{
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 45px;
        left: 0px;
        background: rgba(230, 230, 230, 0.996);
        width: 180px;
        z-index: 99;
        font-size: 0.8em;
        border: 1px solid rgba(0,0,0,.15);

        & .plans_menu_menuList_link.active, & .plans_menu_menuList_link:hover{
            background: #FFFFFF73;
            border-radius: 10px;
        }
    }
    .plans_menu_menuList_subListBigMenu{
        position: absolute;
        display: flex;
        justify-content: space-between;
        top: 90px;
        left: 50%;
        padding: 20px;
        transform: translateX(-50%);
        background: #f8f8f8;
        width: 83%;
        z-index: 99;
        box-shadow: 1px 3px 6px #eeeeee;
        border-radius: 0px 00px 20px 20px;

        @include media(sm,md){
            display: none;
        }
    }
    .plans_menu_menuList_subListBigMenu_menu{
        display: flex;
        flex-direction: column;
    }
    .plans_menu_menuList_subListBigMenu_menu_header{
        color: var(--blue);
        margin-bottom: 20px;
        text-transform: uppercase;
        margin-bottom: 10px;
        font-weight: 600;
    }
    .plans_menu_menuList_subListBigMenu_menu_link{
        display: inline-block;
        color: var(--black);
        margin: 10px 0px;
        opacity: 0.8;

        &:hover{
            opacity: 1;
        }
    }
    .plans_menu_menuList_subListBigMenu_menu_linkSupercharge{
        display: flex;
        align-items: center;
        margin: 20px 0px;

        & img{
            width: 200px;
            margin-right: 7px;
        }
    }
    .plans_menu_menuList_subListBigMenu_menu_link_name{

    }
    .plans_menu_menuList_subListBigMenu_menu_link_description{
        display: inline-block;
        font-size: 0.8em;
        font-weight: 300;
        width: 200px;
    }

    .plans_menu_menuList_subListBigMenuSolutions_menuLeft{
        margin-top: 30px;
    }
    .plans_menu_menuList_subListBigMenuSolutions_menuCenter{
        margin-top: 30px;
    }
    .plans_menu_menuList_subListBigMenuSolutions_menuRight{
        display: flex;
        flex-direction: column;
        width: 470px;
        background: var(--blue);
        color: var(--white);
        padding: 20px;
        border-radius: 10px;

        & > *{
            display: flex;
            flex-direction: row;
        }

        & a {
            color: var(--white);
            opacity: 1;

            &:hover{
                background: #5b94f8;
            }
        }

        & .plans_menu_menuList_subListBigMenu_menu{
            width: 300px;

            & .plans_menu_menuList_subListBigMenu_menu_link{
                margin: 30px 0px;
            }
        }
    }
    .plans_menu_menuList_subListBigMenuSolutions_menuRight_header{
        text-transform: uppercase;
    }

    .plans_planType{
        margin: 50px auto;
        margin-top: 40px;
        margin-bottom: 0px;

        & .content:has(.plans_planType_left){
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include media(sm,md){
                flex-direction: column;
            }
        }

        @include media(sm,md){
            &.containerAdapter{
                width: auto;
            }
        }
    }
    .plans_planType_content{
        text-align: center;
    }
    .plans_planType_left{
        width: 40%;

        @include media(sm,md){
            width: auto;
            text-align: center;
        }
    }
    .plans_planType_content_textTitle,
    .plans_toolsAndFeature_title,
    .plans_questions_title{
        font-size: 2.6em;
        font-weight: 600;
        line-height: 1.2;
        color: var(--black);

        & span{
            color: rgb(1, 212, 185);
        }

        @include media(sm,md){
            font-size: 2em;
        }
    }

    .plans_planType_content_text_action{
        font-size: 0.9em;
        margin-top: 5px;

        & b{
            font-weight: 600;
        }

        & p {
            display: inline-block;
        }

        & span{
            margin-left: 4px;
            color: #0060e3;
            cursor: pointer;
            text-decoration: underline;
            text-underline-offset: 2px;
        }
    }

    .plans_planType_right{
        width: 60%;

        @include media(sm,md){
            width: auto;
        }
    }
    .plans_planType_right_content{
        width: 405px;
        margin: auto;
        margin-left: 170px;

        @include media(sm,md){
            width: auto;
            margin-left: 0px;
        }
    }
    .plans_planType_right_desc{
        margin-bottom: 12px;
        font-size: 1.1em;

        @include media(sm,md){
            font-size: 0.9em;
            text-align: center;
            margin-top: 5px;
        }
    }
    .plans_planType_right_actions{
        display: flex;
        justify-content: center;

        & .btn2, & .btn3{
            padding: 11px 21px;
            margin: 0px;
            margin-right: 33px;
            border: 3px solid rgba(37, 96, 223, 0.996);
            font-weight: 600;

            &:last-child{
                margin-right: 0px;
            }
        }
    }
    .plans_planType_right_actions_action{
        cursor: pointer;

        @include media(sm,md){
            text-align: center;
        }
    }

    .plans_info{
        & .content{
            display: flex;
            justify-content: center;
            margin: 30px auto;
            font-size: 0.8em;

            @include media(sm,md){
                flex-direction: column;
                margin: 10px auto;
            }
        }

        @include media(sm,md){
            &.containerAdapter{
                width: auto;
            }
        }
        
    }
    .plans_info_item{
        display: flex;
        align-items: center;
        margin-right: 20px;
        font-size: 1.2em;

        &:last-child{
            margin-right: 0px;
        }

        @include media(sm,md){
            margin: 1px auto;

            &:last-child{
                margin-right: auto;
            }
        }
    }
    .plans_info_item_icon{
        background: #17dda2;
        color: var(--white);
        padding: 0px 3px;
        border-radius: 50%;
        font-size: 0.8em;
        margin-right: 5px;
    }

    .plans_details{
        margin: 50px auto;
        margin-top: 0px;

        & .content{
            display: flex;
            justify-content: center;
            align-items: stretch;

            @include media(sm,md){
                flex-direction: column;
                align-items: center;
            }
        }

        @include media(sm,md){
            &.containerAdapter{
                width: auto;
            }
        }
    }

    .plans_details_item{
        position: relative;
        min-width: 25%;
        max-width: 250px;
        margin-right: 20px;
        padding: 30px 15px;
        box-shadow: 0px 0px 10px rgba(37, 96, 223, 0.1);
        text-align: center;

        &:last-child{
            margin-right: 0px;
        }

        @include media(sm,md){
            width: auto;
            margin-bottom: 15px;
            margin-right: 0px;
        }
    }
    .plans_details_item_popular{
        position: absolute;
        top: 17px;
        left: 50%;
        font-size: 0.7em;
        display: flex;
        font-weight: bold;
        background: #ffffff;
        border: 1px solid #d0d0d0;
        margin: auto;
        padding: 2px 13px;
        transform: translateX(-50%);
    }
    .plans_details_item_popular_img{
        position: absolute;
        width: 30px;
        top: -9px;
        left: 11px;
        background: #ffffff;
    }
    .plans_details_item_popular_text{
        margin-left: 35px;
        white-space: nowrap;
    }
    .plans_details_item_title{
        font-size: 1.4em;
        font-weight: 600;
        margin-bottom: 10px;
        margin-top: 41px;

        @include media(sm,md){
            font-size: 2em;
        }
    }
    .plans_details_item_subTitle{
        font-size: 0.7em;
        font-weight: 400;
        margin-top: -15px;

        @include media(sm,md){
            font-size: 0.9em;
        }
    }

    .plans_details_item_price{
        font-weight: 600;

        @include media(sm,md){
            font-size: 1.5em;
        }
    }
    .plans_details_item_price_amount{
        color: #263B5E;

        & > strong{
            font-size: 4em;
            font-weight: 600;
        }
    }
    .plans_details_item_price_amountSmallsize{
        & > strong{
            font-size: 1.8em;
        }

        & + .plans_details_item_price_desc{
            margin-top: 0px;
        }
    }
    .plans_details_item_price_amount_cents{
        margin-left: -1px;
    }
    .plans_details_item_price_desc{
        font-size: 0.7em;
        margin-top: -12px;

        @include media(sm,md){
            font-size: 0.9em;
        }
    }

    .plans_details_item_desc{
        display: flex;
        flex-direction: column;
        font-size: 0.8em;
        margin: 0px 18px;
        margin-top: 20px;
        padding: 15px 0px;
        min-height: 185px;
        text-align: left;

        @include media(sm,md){
            min-height: auto;
        }
    }

    .plans_details_item_desc_title{
        font-weight: bold;
        margin-bottom: 5px;
    }

    .plans_details_item_desc_item{

        &.deleted{
            & .icon{
                color: #ef1d1e;
            }
            & del{
                margin-left: 3px;
            }
        }

        @include media(sm,md){
            line-height: 2.2;
        }
    }
    .plans_details_item_desc_itemTools{
        display: list-item;
        list-style: disc;
        margin-left: 30px;
        font-size: 0.9em;
    }
    .plans_details_item_desc_complements{
        margin-bottom: 10px;
    }

    .plans_details_item_desc_item_img{
        width: 12px;
    }

    .plans_details_item_action{
        display: inline-block;
        width: 100%;
        margin-top: 20px;
        transition: all 0.1s ease-in;
        
        &:hover{
            background: #00eba6;
        }
    }
    .plans_details_item_actionCoupon{
        margin-top: 5px;
        font-size: 0.85em;
    }

    .plans_details_item_details{
        font-size: 0.8em;
        margin-top: 25px;
    }
    .plans_details_item_details_title{
        color: #2453b8fe;
        font-weight: 600;
    }
    .plans_details_item_details_desc{
        font-size: 0.9em;
        font-weight: 400;
    }

    .plans_details_item_details_image{
        @include media(sm,md){
            width: 70%;
        }
    }

    .plans_details_item_priceCoupon{
        margin-top: 5px;
        margin-bottom: -5px;
    }
    .plans_details_item_priceCoupon_del{
        font-size: 1.3em;
        color: #e30613;
        font-weight: 500;
    }
    .plans_details_item_priceCoupon_content{
        display: flex;
        align-items: center;
        justify-content: center;

        & .plans_details_item_price_symbol{
            align-self: baseline;
            margin-top: 15px;
            font-size: 1.8em;
            font-weight: 600;
        }

        & .plans_details_item_price_amount{
            display: flex;
            align-items: center;
            text-align: left;

            & .plans_details_item_price_amount_cents{
                margin-top: 9px;
                & p:nth-of-type(1){
                    margin-bottom: -5px;
                }
            }
        }
    }

    .superCharge{
        margin-bottom: 20px;

        & .content{
            display: flex;
            align-items: center;
            justify-content: space-around;
            background-image: url('../assets/background/supercharge-horizontal.jpg');
            background-size: 100% 100%;
            height: 220px;
            box-shadow: 0px 0px 12px #e4e4e4;

            @include media(sm,md){
                background-image: url('../assets/background/supercharge-vertical.jpg');
                background-size: 100% 100%;
                height: 650px;
                flex-direction: column;
                justify-content: start;
            }
        }
    }
    
    .superCharge_title{
        text-align: center;
        font-size: 0.9em;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: 500;
        padding: 0px 20px;
    }

    .superCharge_logo{
        width: 33%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-left: 121px;
        text-align: center;

        @include media(sm,md){
            width: 80%;
            margin-left: 0px;
            margin-top: 80px;
        }
    }
    .superCharge_logo_image{
        width: 200px;

        @include media(sm,md){
            width: 90%;
        }
    }
    .superCharge_logo_namePlan{
        font-size: 1.4em;

        @include media(sm,md){
            font-size: 1.6em;
        }
    }
    .superCharge_logo_description{
        font-size: 0.8em;

        @include media(sm,md){
            font-size: 1em;
        }
    }

    .superCharge_action{
        width: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -13px;

        @include media(sm,md){
            width: 80%;
            margin-top: 10px;
        }
    }
    .superCharge_action_name{
        font-size: 2em;
        font-weight: 600;
        margin-bottom: 5px;
        text-transform: uppercase;

        @include media(sm,md){
            font-size: 3em;
        }
    }
    .superCharge_action_name_action{
        width: 80%;
        text-align: center;
        text-transform: uppercase;
    }
    .superCharge_action_description{
        font-size: 0.7em;
    }

    .superCharge_tools{
        width: 33%;
        display: flex;
        flex-direction: column;
        margin-right: 121px;

        @include media(sm,md){
            width: 50%;
            margin: auto;
            margin-top: 60px;
            font-size: 1.2em;
        }
    }
    .superCharge_tools_title{
        font-weight: 800;
        font-size: 0.8em;
        margin-bottom: 7px;
    }
    .superCharge_tools_tool{
        display: list-item;
        list-style: disc;
        margin-left: 30px;
        font-size: 0.7em;
    }

    .plans_security{
        background: rgba(245, 249, 252, 0.3);
        margin-top: -43px;
        margin-bottom: 67px;

        & .content{
            display: flex;
            justify-content: space-between;
            width: 62%;

            @include media(sm,md){
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
        }
    }
    .plans_security_item{
        font-size: 0.7em;
        display: flex;
        align-items: flex-start;

        @include media(sm,md){
            flex-direction: column;
            width: auto;
            margin-bottom: 15px;
        }
    }
    .plans_security_item_image{
        width: 55px;
        margin-right: 20px;

        @include media(sm,md){
            margin: auto;
        }
    }
    .plans_security_item_infoSecure,
    .plans_security_item_infoGuarantee{
        margin-top: 8px;
        margin-left: -8px;
    }
    .plans_security_item_info_image{
        width: 49px;
    }
    .plans_security_item_info_text{
        font-weight: bold;
        text-transform: uppercase;
    }
    .plans_security_item_info_textSub{
        font-size: 0.9em;
    }

    .plans_toolsAndFeature{
        & .content{
            padding: 60px 0px;
        }

        @include media(sm,md){
            display: none;
        }
    }
    .plans_toolsAndFeature_title{
        text-align: center;
    }

    .plans_toolsAndFeature_table{
        width: 100%;
    }
    .plans_toolsAndFeature_table_header{
        background: #0060e3;
        color: var(--white);
        display: flex;
        padding: 10px 20px;
        border-radius: 5px;
        justify-content: space-between;
        margin-top: 30px;
    }
    .plans_toolsAndFeature_table_textStart{
        font-weight: 600;
    }
    .plans_toolsAndFeature_table_header_check{
        display: flex;
        align-items: center;
        width: 490px;
    }
    .plans_toolsAndFeature_table_header_check_text{
        font-size: 0.8em;
        font-weight: 500;
        margin-left: 20px;
        padding-left: 20px;
        border-left: 3px solid var(--white);
    }

    .plans_toolsAndFeature_table_item_content{
        position: relative;
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 10px 20px;
        background: #f4f9fc;
        border-radius: 5px;
        margin: 10px auto;
        cursor: pointer;
        flex-wrap: wrap;
        
        &.plans_toolsAndFeature_table_item_contentSub{
            cursor: default;
        }
    }
    .plans_toolsAndFeature_table_item_dropdown{
        position: absolute;
        background: #f3f9fc;
        box-shadow: 0px 0px 3px #b5b5b5;
        padding: 3px;
        left: -11px;
        border-radius: 50%;
    }
    .plans_toolsAndFeature_table_item_text{
        font-weight: 600;
    }
    .plans_toolsAndFeature_table_item_check{
        display: flex;
        width: 490px;
        justify-content: space-around;
    }
    .plans_toolsAndFeature_table_item_check_check{
        width: 25%;
        text-align: center;

        & img{
            width: 20px;
        }
    }

    .plans_questions{
        & .content{
            padding: 60px 50px;

            @include media(sm,md){
                padding: 0px;
            }
        }
    }

    .plans_questions_container{
        display: flex;
        justify-content: flex-start;

        @include media(sm,md){
            flex-direction: column;
        }
    }
    .plans_questions_title{
        width: 300px;

        @include media(sm,md){
            width: auto;
            text-align: center;
        }
    }
    .plans_questions_questions{
        width: 100%;
        margin-left: 40px;

        @include media(sm,md){
            margin-left: 0px;
        }
    }

    .plans_questions_questions_question_text{
        font-weight: 600;
        color: var(--black);
        cursor: pointer;
        background: #fafcfd;
        padding: 20px;
    }
    .plans_questions_questions_question_reply{
        padding: 20px;
    }

    .plans_footer{
        background: #f2f2f2;
        color: #000;

        & .content{
            display: flex;
            justify-content: space-between;
            padding: 80px 0px;

            @include media(sm,md){
                flex-direction: column;
            }
        }
    }
    .plans_footer_block{
        margin-right: 10px;

        &:last-child{
            margin-right: 0px;
        }

        @include media(sm,md){
            border-bottom: 1px solid var(--gray-500);
            margin-bottom: 15px;
            padding-bottom: 15px;
            text-align: center;
        }
    }

    .plans_footer_blockAbout{
        font-size: 0.8em;
        min-width: 220px;
        flex-basis: 220px;

        @include media(sm,md){
            flex-basis: auto;
        }
    }
    .plans_footer_blockAbout_title{
        font-weight: 600;
    }

    .plans_footer_blockMenu1,
    .plans_footer_blockMenu2,
    .plans_footer_blockMenu3{
        text-transform: uppercase;
        font-size: 0.82em;

        & li a {
            display: inline-block;
            color: var(--black);
            width: 100%;
            padding: 10px 20px;
            font-weight: 600;

            &:hover{
                background: #fafafa;
            }
        }
    }
    .plans_footer_blockMenu1{
        width: 410px;

        @include media(sm,md){
            width: auto;
        }
    }
    .plans_footer_blockMenu2{
        width: 410px;

        @include media(sm,md){
            width: auto;
        }
    }
    .plans_footer_blockMenu3{
        width: 400px;

        @include media(sm,md){
            width: auto;
        }
    }

    .plans_footer_blockMidiaSocial{
        display: flex;
        font-size: 0.8em;
        width: 510px;

        @include media(sm,md){
            width: auto;
            flex-direction: column;
        }
    }
    .plans_footer_blockMidiaSocial_icons{
        display: flex;
        flex-direction: column;
        font-size: 1.1em;
        margin-right: 7px;

        & .icon{
            background: #17191e;
            padding: 6px;
            margin-top: 3px;

            &:first-child{
                margin-top: 0px;
            }
        }

        @include media(sm,md){
            align-items: center;
            justify-content: center;
            flex-direction: row;
        }
    }
</style>